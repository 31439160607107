import { Customer } from "./customer";

export const CUSTOMERS: Customer[] = [
  {
    isActive: false,
    id: 'SC-PL',
    company: 'Weingut Paul Lehrner',
    lastName: 'Gertrude',
    firstName: 'Lehrner',
    vat: 'ATU123456',
    address: 'Hauptstraße 56, Horitschon'
  },{
    isActive: false,
    id: 'SC-WX',
    company: 'weXelerate',
    lastName: '',
    firstName: '',
    vat: 'ATU123456',
    address: 'Schwedenplatz 1'
  },

]
