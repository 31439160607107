import { Component, OnInit } from '@angular/core';
import {CUSTOMERS } from "./mock-customers";
import {TabsModule} from "ngx-bootstrap/tabs";

@Component({
  selector: 'app-sc-customers',
  templateUrl: './sc-customers.component.html',
  styleUrls: ['./sc-customers.component.css']
})

export class ScCustomersComponent implements OnInit {

  public customers = CUSTOMERS;

  constructor() {
    console.log(this.customers)
  }

  ngOnInit(): void {
  }

}
