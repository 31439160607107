import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpRequest, HttpHandler, HttpInterceptor, HttpEvent} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class JsonloaderService implements HttpInterceptor {
  constructor(public http: HttpClient) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body) {
      const duplicate = req.clone({
        body: req.body.replace(/pizza/gi, '🍕')
      });
      return next.handle(duplicate);
    }
    return next.handle(req);
  }

}
