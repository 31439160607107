<div class="container">
  <div class="col pt-5">
    <tabset>
      <tab *ngFor="let customer of customers" bind-heading="customer.company" bind-id="customer.company"
           [active]="isActiveTab(customer.id)"
           (selectTab)="setActiveTab(customer.id)">

        <div class="row align-items-center  pt-5">
          <div class="col-12 aos-init aos-animate" data-aos="fade-right">
            <!-- Heading -->
            <h2 class="mb-5">
              {{ customer.company }}<br>
              <span class="text-primary">
                <small>{{ customer.id }}</small>
              </span>
            </h2>

            <!-- List -->
            <div class="d-flex" *ngFor="let list of activeList">
              <!-- Icon -->
              <div class="icon text-primary">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M12 8H8a4 4 0 00-4 4v1a3 3 0 003 3v2a5 5 0 01-5-5v-1a6 6 0 016-6h4V4.728a.5.5 0 01.8-.4l2.856 2.133a.5.5 0 01-.001.802l-2.857 2.121A.5.5 0 0112 8.983V8z" fill="#335EEA"></path><path d="M12.058 16H16a4 4 0 004-4v-1a3 3 0 00-3-3V6a5 5 0 015 5v1a6 6 0 01-6 6h-3.942v.983a.5.5 0 01-.798.401l-2.857-2.12a.5.5 0 010-.803l2.856-2.134a.5.5 0 01.8.401V16z" fill="#335EEA" opacity=".3"></path></g></svg>
              </div>

              <div class="ml-5">

                <!-- Heading -->
                <h4 class="mb-1">{{ list.docTitle }}</h4>

                <!-- Text -->
                <p class="text-muted mb-6">
                  Anzahl von Stunden: {{ list.includedHours }}
                </p>

                <table class="table table-bordered">
                  <tbody>
                  <tr *ngFor="let entry of list.entries">
                    <td>{{entry.title}}</td>
                    <td class="whitespace-no-wrap">{{entry.date}}</td>
                    <td  class="text-right">{{entry.amount }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="3"></td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">Stunden insgesamt</td>
                    <td class="text-right">{{list.includedHours }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">Verbrauchte Stunden</td>
                    <td class="text-right">{{this.getListAmount(list)}}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">Übrige Stunden</td>
                    <td class="text-right">{{this.getLeftHours(list)}}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </div>

</div>
