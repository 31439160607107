import {Component, NgModule, OnInit, ViewChild} from '@angular/core';
import { CUSTOMERS } from "../sc-customers/mock-customers";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sc-lists',
  templateUrl: './sc-lists.component.html',
  styleUrls: ['./sc-lists.component.css']
})
export class ScListsComponent implements OnInit {
  public customers = CUSTOMERS;
  lists: any = [];
  activeTab: string = '';
  activeList: any;

  constructor(private _http: HttpClient) {
    const initCustomerId = 'SC-PL';
    this.activeList = this.getCustomerList(initCustomerId);
    this.activeTab = initCustomerId;
  }

  private isActiveTab(customerId){
    return this.activeTab === customerId
  }

  private setActiveTab(customerId){
    this.activeTab = customerId;
    this.activeList = this.getCustomerList(customerId);
  }

  private getListAmount(list: any){
    let total = 0;
    for(const entry in list.entries){
      total = total + list.entries[entry].amount;
    }
    return total;
  }

  private getLeftHours(list: any){
    return list.includedHours - this.getListAmount(list)
  }

  private getCustomerList(customerId){
    const url = `https://sclist.solid-creation.com/${customerId.toLowerCase()}.json`;
    this._http.get(url).subscribe(res => {
      this.activeList = res['lists'];
    });
  }

  ngOnInit(): void {}
}
