import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { setTheme } from 'ngx-bootstrap/utils';
import {TabsetComponent, TabsModule} from "ngx-bootstrap/tabs";

import { ScListsComponent } from './sc-lists/sc-lists.component';
import { ScCustomersComponent } from './sc-customers/sc-customers.component';
import {JsonloaderService} from "./sc-lists/jsonloader.service";

const appRoutes: Routes = [
  { path: 'lists', component: ScListsComponent },
  { path: 'customers', component: ScCustomersComponent },
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    ),
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
    ScListsComponent,
    ScCustomersComponent,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: JsonloaderService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    setTheme('bs4');
  }
}
